import React, {useEffect, useState} from "react";
import { Grid, Button } from "@mui/material";
import axios from "axios";
import Endpoints from '../../../Endpoints.json';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from "@mui/material/TextField";
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const Update = () => {
    const [stage,setStage] = useState(1);
    const [dataList, setDataList] = useState([])
    const [showingList, setShowingList] = useState([])
    const [search, setSearch] = useState('')

    useEffect(()=>{
        axios({
            method: "post",
            url: Endpoints.getBusinessList,
          })
          .then(res => {
            setDataList(res.data.Items)
            setShowingList(res.data.Items)
        })
          .catch(err=>console.log(err))
    },[])

    useEffect(()=>{
        setShowingList(dataList.filter(i => JSON.stringify(i).toLocaleLowerCase().includes(search.toLocaleLowerCase())))
    },[search])

  return <Grid style={{ margin: "20px" }}>
   <h1>Business List:</h1>

      <div>
        Welcome back to SmallNeighborhood Admin Portal! Remember, everything you
        do has big impact! Be careful!
      </div>
      <br />
      {stage === 1 && (
        <>
        <TextField value={search}
            onChange={(e) =>
              setSearch(e.target.value)
            }
            style={{ margin: "20px", width: '80%' }}
            label="Search"
            variant="outlined"/>
      <TableContainer component={Paper}>
      <Table sx={{  }} style={{cursor: "pointer"}} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Business ID</StyledTableCell>
            <StyledTableCell>Active</StyledTableCell>
            <StyledTableCell>DBA Name</StyledTableCell>
            <StyledTableCell>Legal Name</StyledTableCell>
            <StyledTableCell>Address</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {showingList.map((row)=>(
            <StyledTableRow  key={row.salonID}>
<StyledTableCell component="th" scope="row">{row.salonID.slice(4)}</StyledTableCell>
<StyledTableCell >{row.active ? "YES" :"NO"}</StyledTableCell>
<StyledTableCell align="right">{row.DBAName}</StyledTableCell>
<StyledTableCell align="right">{row.legalName}</StyledTableCell>
<StyledTableCell align="right">{row.address}, {row.city}, {row.state}</StyledTableCell>
            </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
    )}
  </Grid>;

};
export default Update;
