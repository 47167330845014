import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import React from "react";

const Account = (props) => {


  const logout = ()=>{
    localStorage.removeItem('username')
    localStorage.removeItem('password')
    window.location.reload()
  }

  return (
    <>
      <FormControl>
        <Button onClick={()=>logout()}>Log Out</Button>
      </FormControl>
    </>
  );
};

export default Account;
