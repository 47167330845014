import { createTheme } from "@mui/material/styles";

//override MUI styles
export const theme = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          //   fontSize: "10rem",
        },
      },
    },
  },

//   palette: {
//     primary: {
//       main: "#f5593d",
//     },
//   },
});
