import { theme } from "../../theme";

const useStyle = () => ({
  root: {
    paddingRight: "16px",
    paddingLeft: "16px",
    paddingTop: "16px",
  },
  title: {
    fontSize: "25px",
    fontWeight: "bold",
    textAlign: "center",
    paddingBottom: "10%",
    paddingTop: "5%",
  },
});

export default useStyle;
