import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import useStyle from "./style";
import axios from "axios";
import Endpoints from '../../Endpoints.json'

export default function LandingPage() {
  const style = useStyle();
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const login =  () => {
    axios({
        method: "post",
        url: Endpoints.loginSalon,
        data: JSON.stringify({
          username,
          password,
        }),
      })
        .then((res) => {
            localStorage.setItem("username", res.data.username);
            localStorage.setItem("password", res.data.password);
            window.location.reload();
        })
        .catch(() => {
            alert("Wrong username or password, please try again or contact our support!")
        });
  };

  return (
    <Grid container justifyContent="center" style={style.root}>
      <Grid item xs={12} style={style.title}>
        Welcome to NailBossAdmin
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sm={6}
        xs={10}
      >
        <Grid item xs={12}>
          <TextField
            fullWidth
            onChange={(e) => setUserName(e.target.value)}
            value={username}
            label="Username"
            type="text"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            label="Password"
            type="password"
          />
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth variant="contained" onClick={() => login()}>
            Log In
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
