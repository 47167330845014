import Grid from "@mui/material/Grid";
import React from "react";
import { useNavigate } from "react-router-dom";
import BottomNavBar from "../../common/BottomNavBar";
import ButtonIcon from "../../common/ButtonIcon";
import Account from "./Account";
import Update from "./Update";
import Support from "./Support";
import Billing from "./Billing";
import useStyle from "./style";
import NewBiz from "./NewBiz";

function HomePage(props) {
//   const navigate = useNavigate();
  const [currentPage, setCurrentPage] = React.useState(0);
  const style = useStyle();

  return (
    <Grid container justifyContent="center" spacing={1} style={style.root}>
      {currentPage === 0 && (
        <NewBiz />
      )}

      {currentPage === 1 && (
        <>
          <Update />
        </>
      )}
      {currentPage === 2 && (
        <>
          <Billing />
        </>
      )}
      {currentPage === 3 && (
        <>
          <Support />
        </>
      )}

      {currentPage === 4 && (
        <Account language={props.language} setLanguage={props.setLanguage} />
      )}
      <BottomNavBar
        {...props}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </Grid>
  );
}

export default HomePage;
