import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import React from "react";
import useStyle from "./style";

const ButtonIcon = (props) => {
  const style = useStyle();

  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="flex-start"
      style={style.button}
    >
      <Button onClick={props.onClick} style={style.button} variant="text">
        <Grid item xs={12}>
          <Grid item xs>
            {props.icon}
          </Grid>
          <Grid item xs style={style.label}>
            {props.label}
          </Grid>
        </Grid>
      </Button>
    </Grid>
  );
};

export default ButtonIcon;
