import { Grid, Button } from "@mui/material";
import React from "react";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import axios from "axios";
import Endpoints from "../../../Endpoints.json";
import Box from "@mui/material/Box";

// import Button from "@mui/material/Button";
const NewBiz = (props) => {
  const [validForm, setValidForm] = React.useState(true);
  const [success, setSuccess] = React.useState(false);
  const [failed, setFailed] = React.useState(false);
  const [newBizObj, setNewBizObj] = React.useState({
    salonID: "",
    legalName: "",
    DBAName: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    businessType: "",
    totalWithhold: "",
    fedWithhold: "",
    stateWithhold: "",
    ssWithhold: "",
    medicareWithhold: "",
    miscWithhold: "",
    ownerCode: "",
    payrollService: false,
    posService: false,
    merchantService: false,
  });

  const submitNewBiz = () => {
    props.setLoadingSpinner(true);
    axios({
      method: "post",
      url: Endpoints.addNewBusiness,
      data: JSON.stringify(newBizObj),
    })
      .then((res) => {
        props.setLoadingSpinner(false);
        setSuccess(true)
      })
      .catch((err) => {
        props.setLoadingSpinner(false);
        setSuccess(false);
      });
  };

  return (
    <>

        <Grid style={{ margin: "20px" }}>
          <h1>Add new business</h1>
          <div>
            Welcome back to SmallNeighborhood Admin Portal! Remember, everything
            you do has big impact! Be careful!
          </div>
          <br />
          <h3>Business Info:</h3>
          <TextField
            value={newBizObj.salonID}
            onChange={(e) =>
              setNewBizObj({
                ...newBizObj,
                salonID: e.target.value.toUpperCase(),
              })
            }
            style={{ margin: "20px" }}
            label="Business ID (Client Code)"
            variant="outlined"
          />
          <TextField
            value={newBizObj.legalName}
            onChange={(e) =>
              setNewBizObj({ ...newBizObj, legalName: e.target.value })
            }
            style={{ margin: "20px" }}
            label="Business Legal Name"
            variant="outlined"
          />
          <TextField
            value={newBizObj.DBAName}
            onChange={(e) =>
              setNewBizObj({ ...newBizObj, DBAName: e.target.value })
            }
            style={{ margin: "20px" }}
            label="Business DBA Name"
            variant="outlined"
          />
          <TextField
            value={newBizObj.address}
            onChange={(e) =>
              setNewBizObj({ ...newBizObj, address: e.target.value })
            }
            style={{ margin: "20px" }}
            label="Business Address"
            variant="outlined"
          />
          <TextField
            value={newBizObj.city}
            onChange={(e) =>
              setNewBizObj({ ...newBizObj, city: e.target.value })
            }
            style={{ margin: "20px" }}
            label="Business City"
            variant="outlined"
          />
          <TextField
            value={newBizObj.state}
            onChange={(e) =>
              setNewBizObj({ ...newBizObj, state: e.target.value })
            }
            style={{ margin: "20px" }}
            label="Business State"
            variant="outlined"
          />
          <TextField
            value={newBizObj.zipcode}
            onChange={(e) =>
              setNewBizObj({ ...newBizObj, zipcode: e.target.value })
            }
            style={{ margin: "20px" }}
            label="Business Zipcode"
            variant="outlined"
          />

          <TextField
            value={newBizObj.businessType}
            onChange={(e) =>
              setNewBizObj({ ...newBizObj, businessType: e.target.value })
            }
            style={{ margin: "20px" }}
            label="Business Type"
            variant="outlined"
          />

          <br />
          <h3>Tax Info:</h3>
          <TextField
            type="number"
            value={newBizObj.totalWithhold}
            onChange={(e) =>
              setNewBizObj({ ...newBizObj, totalWithhold: e.target.value })
            }
            style={{ margin: "20px" }}
            label="Total Tax Withholding"
            variant="outlined"
          />
          <TextField
            type="number"
            value={newBizObj.fedWithhold}
            onChange={(e) =>
              setNewBizObj({ ...newBizObj, fedWithhold: e.target.value })
            }
            style={{ margin: "20px" }}
            label="Federal Tax Witholding"
            variant="outlined"
          />
          <TextField
            type="number"
            value={newBizObj.stateWithhold}
            onChange={(e) =>
              setNewBizObj({ ...newBizObj, stateWithhold: e.target.value })
            }
            style={{ margin: "20px" }}
            label="State Tax Withholding"
            variant="outlined"
          />
          <TextField
            type="number"
            value={newBizObj.ssWithhold}
            onChange={(e) =>
              setNewBizObj({ ...newBizObj, ssWithhold: e.target.value })
            }
            style={{ margin: "20px" }}
            label="Social Security Withholding"
            variant="outlined"
          />
          <TextField
            type="number"
            value={newBizObj.medicareWithhold}
            onChange={(e) =>
              setNewBizObj({ ...newBizObj, medicareWithhold: e.target.value })
            }
            style={{ margin: "20px" }}
            label="Medicare Withholding"
            variant="outlined"
          />
          <TextField
            type="number"
            value={newBizObj.miscWithhold}
            onChange={(e) =>
              setNewBizObj({ ...newBizObj, miscWithhold: e.target.value })
            }
            style={{ margin: "20px" }}
            label="Misc Withholding"
            variant="outlined"
          />
          <br />
          <h3>Service Choice:</h3>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={newBizObj.posService}
                  onChange={(e) =>
                    setNewBizObj({ ...newBizObj, posService: e.target.checked })
                  }
                />
              }
              label="POS Service"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={newBizObj.merchantService}
                  onChange={(e) =>
                    setNewBizObj({
                      ...newBizObj,
                      merchantService: e.target.checked,
                    })
                  }
                />
              }
              label="Merchant Service"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={newBizObj.payrollService}
                  onChange={(e) =>
                    setNewBizObj({
                      ...newBizObj,
                      payrollService: e.target.checked,
                    })
                  }
                />
              }
              label="Payroll Service"
            />
          </FormGroup>
          <br />
          <h3>Account Info:</h3>
          <TextField
            value={newBizObj.ownerCode}
            type="number"
            onChange={(e) =>
              setNewBizObj({ ...newBizObj, ownerCode: e.target.value })
            }
            style={{ margin: "20px" }}
            label="Owner Code"
            variant="outlined"
          />
          <br />
          {success && <h3 style={{color: 'red'}}>Business Created</h3>}
          {failed &&<h3  style={{color: 'red'}}>Failed to create business</h3>}
          <div style={{ textAlign: "center" }}>
            <Button onClick={submitNewBiz} disabled={newBizObj.salonID === ''} variant="contained">
              Create
            </Button>
          </div>
        </Grid>
    </>
  );
};
export default NewBiz;
