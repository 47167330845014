import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./views/HomePage";
import LandingPage from "./views/LandingPage";
import axios from "axios";
import Endpoints from "./Endpoints.json";

export default function App() {
  const username = localStorage.getItem("username");
  const password = localStorage.getItem("password");

  if (username && password) {
    axios({
      method: "post",
      url: Endpoints.checkLogin,
      data: JSON.stringify({ username, password }),
    }).catch(() => {
      localStorage.removeItem("username");
      localStorage.removeItem("password");
    });
  }

  return (
    <>
      {username && password ? (
        <div>
          <BrowserRouter>
            <Routes>
              <Route
                path="/*"
                element={
                  <HomePage />
                }
                />
            </Routes>
          </BrowserRouter>
        </div>
      ) : (
        <div>
          <LandingPage />
        </div>
      )}
    </>
  );
}
