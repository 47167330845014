import HomeIcon from "@mui/icons-material/Home";
import MailIcon from "@mui/icons-material/Mail";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ReceiptIcon from "@mui/icons-material/Receipt";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Box from "@mui/material/Box";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import * as React from "react";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
export default function BottomNavBar(props) {
  return (
    <Box
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
      style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)" }}
    >
      <BottomNavigation
        showLabels
        value={props.currentPage}
        onChange={(event, newValue) => {
          props.setCurrentPage(newValue);
        }}
        style={{ root: "#F5F5F5", paddingBottom: "10px" }}
      >
        <BottomNavigationAction
          label="New"
          icon={<AddCircleOutlineIcon />}
        />
        <BottomNavigationAction
          label="Update"
          icon={<SyncAltIcon />}
        />
        <BottomNavigationAction
          label="Billing"
          icon={<RequestQuoteIcon />}
        />
        <BottomNavigationAction
          label="Support"
          icon={<MailIcon />}
        />
        <BottomNavigationAction
          label="Account"
          icon={<ManageAccountsIcon />}
        />
      </BottomNavigation>
    </Box>
  );
}
